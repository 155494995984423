<template>
  <v-container style="height: 100%">
    <template>
      <!-- forcing prefetching -->
      <v-img :src="twitterLogo" height="1" width="1"></v-img>
      <v-img :src="youtubeLogo" height="1" width="1"></v-img>
      <v-img :src="greenLogo" height="1" width="1"></v-img>
    </template>
    
    <v-row
      class="fill-height"
      justify="center"
      align="start"
      no-gutters
      v-if="!loading"
    >
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" class="text-start">
            <v-img :src="require('../assets/logo.png')" max-width="250"></v-img>
          </v-col>
          <v-col cols="12" md="8" class="px-2">
            <v-row justify="center" v-if="!generated">
              <v-col cols="12">
                <v-tabs
                  v-model="tab"
                  grow
                  :show-arrows="$vuetify.breakpoint.smAndDown"
                  color="warning"
                >
                  <v-tab v-for="item in tabsTitle" :key="item.name">
                    <span>{{ item.name }}</span>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="item in tabsItems"
                    :key="item.name"
                    class="py-4"
                  >
                    <template v-if="tab == 0">
                      <v-form v-model="tabsItems[0].valid">
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model.trim="url"
                              outlined
                              hide-details="auto"
                              label="رابط الموقع"
                              :rules="[rules.required, rules.link]"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="text-center">
                            <v-btn
                              @click="loadingScreen"
                              color="green"
                              class="white--text"
                              :disabled="!tabsItems[0].valid"
                            >
                              إنشاء رمز QR
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </template>

                    <template v-else-if="tab == 1">
                      <v-form v-model="tabsItems[1].valid">
                        <v-row dense>
                          <v-col cols="12">
                            <v-alert type="warning" class="mb-0">
                              يدعم بطاقة العمل الإلكترونية بالبيانات الإنجليزية فقط
                            </v-alert>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.trim="firstname"
                              outlined
                              hide-details="auto"
                              label="الاسم الاول"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.trim="lastname"
                              outlined
                              hide-details="auto"
                              label="اسم العائلة"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.trim="organization"
                              outlined
                              hide-details="auto"
                              label="اسم الشركة"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.trim="title"
                              outlined
                              hide-details="auto"
                              label="المسمى الوظيفي"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.trim="phone"
                              outlined
                              hide-details="auto"
                              label="الهاتف الثابت"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.trim="telephone"
                              outlined
                              hide-details="auto"
                              label="الهاتف المحمول"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.trim="website"
                              outlined
                              hide-details="auto"
                              label="الموقع الإلكتروني"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.trim="email"
                              outlined
                              hide-details="auto"
                              label="البريد الإلكتروني"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="text-center">
                            <v-btn
                              @click="loadingScreenBusiness"
                              color="green"
                              class="white--text"
                              :disabled="!tabsItems[1].valid"
                            >
                              إنشاء رمز QR
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </template>

                    <template v-else-if="tab == 2">
                      <v-form v-model="tabsItems[2].valid">
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model.trim="twitterAccount"
                              outlined
                              hide-details="auto"
                              label="معرف الحساب"
                              :rules="[rules.required]"
                              suffix="@"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="text-center">
                            <v-btn
                              @click="loadingScreenTwitter"
                              color="green"
                              class="white--text"
                              :disabled="!tabsItems[2].valid"
                            >
                              إنشاء رمز QR
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </template>

                    <template v-else-if="tab == 3">
                      <v-form v-model="tabsItems[3].valid">
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model.trim="youtubeAccount"
                              outlined
                              hide-details="auto"
                              label="حساب اليوتيوب"
                              :rules="[rules.required]"
                              suffix="https://www.youtube.com/c/"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="text-center">
                            <v-btn
                              @click="loadingScreenYoutube"
                              color="green"
                              class="white--text"
                              :disabled="!tabsItems[3].valid"
                            >
                              إنشاء رمز QR
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </template>

                    <template v-else>
                      <v-row justify="center">
                        <v-col cols="12">
                          <v-text-field
                            v-model="url"
                            outlined
                            :label="item.name"
                            :suffix="item.pre"
                            dir="ltr"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-col cols="6" v-if="expand">
                        <v-text-field
                          v-model="name"
                          outlined
                          :label="item.name"
                          :suffix="item.pre"
                          dir="ltr"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" align-self="" v-if="expand">
                        <v-file-input
                          accept="image/*"
                          outlined
                          @change="getPicture()"
                          prepend-icon="mdi-camera"
                          label="الصورة المركزية"
                          disabled
                        ></v-file-input>
                      </v-col>
                      <v-row justify="end" v-if="!generated">
                        <v-col cols="auto">
                          <v-btn
                            dark
                            @click="expand = !expand"
                            color="blue"
                            disabled
                            >معلومات إضافية</v-btn
                          >
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            dark
                            @click="loadingScreen(item.pre, item.img)"
                            color="green"
                            :disabled="!validation"
                            >إنشاء رمز QR</v-btn
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>

            <v-row v-else justify="center">
              <v-col cols="12">
                <v-row justify="center" dense>
                  <v-col cols="12">
                    <div id="canvas" style="text-align: center;"></div>
                  </v-col>
                  <v-col cols="auto">
                    <v-alert color="#630e2b" dense class="mb-0" outlined>
                      في حالة عدم ظهور الشعار يرجى الضغط على أحد أزرار الثيمات أدناه مرتان
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row justify="center" class="text-center">
                  <v-col cols="auto">
                    <v-btn
                      @click="changeThemeQ1"
                      width="126"
                      color="#630e2b"
                      class="ma-1 white--text"
                      rounded
                      >
                      قطر 2022 - 1
                    </v-btn>
                    <v-btn
                      @click="changeThemeQ2"
                      width="126"
                      color="#aa243c"
                      class="ma-1 white--text"
                      rounded
                      >
                      قطر 2022 - 2
                    </v-btn>
                    <v-btn
                      @click="changeThemeQ3"
                      width="126"
                      color="#8e132f"
                      class="ma-1 white--text"
                      rounded
                      >
                      قطر 2022 - 3
                    </v-btn>
                    <v-btn
                      @click="changeThemeQ4"
                      width="126"
                      color="#70184a"
                      class="ma-1 white--text"
                      rounded
                      >
                      قطر 2022 - 4
                    </v-btn>
                    <v-btn
                      @click="changeThemeQ5"
                      width="126"
                      color="#56183c"
                      class="ma-1 white--text"
                      rounded
                      >
                      قطر 2022 - 5
                    </v-btn>
                    <v-btn
                      @click="changeThemeG"
                      width="126"
                      color="#128c78"
                      class="ma-1 white--text"
                      rounded
                      >
                      اليوم الوطني
                    </v-btn>
                    <v-btn
                      @click="changeThemeBW"
                      class="ma-1 black--text"
                      outlined rounded
                      >
                      ثيم الأبيض والأسود
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row justify="center" class="text-center">
                  <v-col cols="12">
                    <v-btn class="ma-1" dark width="115" color="green" @click="downloadPNG()" outlined rounded>
                      تحميل PNG
                    </v-btn>
                    <v-btn class="ma-1" dark width="115" color="green" @click="downloadJPEG()" outlined rounded>
                      تحميل JPEG
                    </v-btn>
                    <v-btn class="ma-1" dark width="115" color="green" @click="downloadSVG()" outlined rounded>
                      تحميل SVG
                    </v-btn>
                  </v-col>

                  <v-col cols="12">
                    <v-btn class="ma-1" dark color="blue" @click="resetInfo()" outlined rounded>
                      إنشاء رمز آخر
                      <v-icon right>mdi-redo-variant</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col v-if="userHasAdblock" cols="auto">
                <v-img :src="require('../assets/bottom.jpg')" contain></v-img>
              </v-col>
              <v-col cols="auto" style="width: 500px; height: 200px" v-else>
                <GoogleAdSense id="4064339375"></GoogleAdSense>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
            <v-row justify="center">
              <v-col cols="auto" v-if="userHasAdblock">
                <v-img :src="require('../assets/side.jpg')" contain></v-img>
              </v-col>
              <v-col cols="auto" style="height: 500px; width: 250px" v-else>
                <GoogleAdSense id="7212698152"></GoogleAdSense>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-card outlined flat>
              <v-card-title>
                <v-spacer></v-spacer>
                هذا المشروع برعاية
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-container class="pa-0">
                  <v-row dense justify="center">
                    <v-col cols="6" md="4" lg="3">
                      <v-card
                        outlined
                        flat
                        href="https://zaahip.app/"
                        target="_blank"
                      >
                        <v-card-text class="text-center pa-0">
                          <v-avatar tile :size="$vuetify.breakpoint.smAndUp ? 150: 100">
                            <v-img
                              :src="require('../assets/zaahip.png')"
                              contain
                            ></v-img>
                          </v-avatar>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="3">
                      <v-card
                        outlined
                        flat
                        href="https://bitcode.sa/"
                        target="_blank"
                      >
                        <v-card-text class="text-center pa-0">
                          <v-avatar tile :size="$vuetify.breakpoint.smAndUp ? 150: 100">
                            <v-img
                              :src="require('../assets/bitcode.png')"
                              contain
                            ></v-img>
                          </v-avatar>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      class="fill-height"
      justify="center"
      align="end"
      no-gutters
      v-if="loading"
    >
      <v-col cols="12" class="text-center">
        <h1>يتم الآن التحميل</h1>
      </v-col>
      <v-col cols="8">
        <v-img
          v-if="userHasAdblock"
          :src="require('../assets/side.jpg')"
          contain
        ></v-img>
        <GoogleAdSense id="4064339375" v-else></GoogleAdSense>
      </v-col>
    </v-row>

    <v-dialog v-model="showAdBlockNotice" max-width="450">
      <v-card>
        <v-card-text class="pt-6 text-center">
          <v-icon size="48" color="warning">mdi-alert</v-icon>
        </v-card-text>
        <v-card-title style="word-break: normal" class="text-center">
          هذا المشروع يعتمد على وجود إعلانات, للإستمرار في دعم المشروع الرجاء
          إيقاف مانع الإعلانات.
        </v-card-title>
        <v-card-text class="text-center pt-4">
          <v-btn class="success" @click="showAdBlockNotice = false"
            >موافق</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import QRCodeStyling from "qr-code-styling";

import GoogleAdSense from "../components/GoogleAdSense";

import twitterLogo from "../assets/blue.png";
import youtubeLogo from "../assets/red.png";
import greenLogo from "../assets/g.png";
// import whiteLogo from "../assets/w.png";

// qatar 2022
import qatar1 from '../assets/qatar2022/1.png'
import qatar2 from '../assets/qatar2022/2.jpg'
import qatar3 from '../assets/qatar2022/3.webp'
import qatar4 from '../assets/qatar2022/4.png'
import qatar5 from '../assets/qatar2022/5.png'

import rulesMixin from "@/mixins/rules-mixin";
import AdblockDetector from "adblock-detector";

const qrCodeSize = 1000;
const qrCodeImageSize = 0.5;

export default {
  mixins: [rulesMixin],
  components: {
    GoogleAdSense,
  },
  data() {
    return {
      generated: false,
      url: null,
      urlValidation: "/^w+[0-9A-z]*[.]w+/",
      validation: false,
      qr: null,
      expand: false,
      picture: null,
      name: null,
      loading: false,
      tab: null,
      tabsTitle: [
        { name: "الموقع الإلكتروني", disabled: false },
        { name: "بطاقة العمل الإلكترونية", disabled: true },
        { name: "حساب تويتر", disabled: false },
        { name: "حساب اليوتيوب", disabled: true },
      ],
      tabsItems: [
        {
          name: "رابط الموقع",
          valid: false,
        },
        {
          name: "بطاقة العمل الإلكترونية",
          valid: false,
        },
        {
          name: "معرف الحساب",
          valid: false,
        },
        {
          name: "حساب اليوتيوب",
        },
      ],
      i: 0,

      firstname: null,
      lastname: null,
      organization: null,
      title: null,
      phone: null,
      telephone: null,
      website: null,
      email: null,
      twitterAccount: null,
      youtubeAccount: null,
      userHasAdblock: AdblockDetector.detect(),
      showAdBlockNotice: false,
      storedData: null,
      twitterLogo: twitterLogo,
      youtubeLogo: youtubeLogo,
      greenLogo: greenLogo,
    };
  },
  computed: {
    isDevSpeed() {
      if (("" + window.location).includes("localhost")) {
        return 0.2;
      }

      return 1;
    },
  },
  mounted() {
    // this.loading = true;
    // this.loadingScreen();
    //this.checkAds();
    this.recordVisit();
    if (this.userHasAdblock) {
      this.showAdBlockNotice = true;
    }
  },
  watch: {
    url() {
      if (this.url != null && this.url != "") {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },
  },
  methods: {
    checkAds() {
      setInterval(() => {
        this.updateAds();
      }, 3000 * this.isDevSpeed);
    },
    updateAds() {
      this.i += this.i;
    },
    getPicture() {
      this.picture = event.target.files[0];
    },
    loadingScreen() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        if (this.url != null) {
          this.generateQR();
        }
      }, 5000 * this.isDevSpeed);
    },
    loadingScreenTwitter() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        if (this.twitterAccount != null) {
          this.picture = twitterLogo;
          this.url = "https://twitter.com/" + this.twitterAccount;
          this.generateQR();
        }
      }, 5000 * this.isDevSpeed);
    },
    loadingScreenYoutube() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        if (this.youtubeAccount != null) {
          this.picture = youtubeLogo;
          this.url = "https://www.youtube.com/c/" + this.youtubeAccount;
          this.generateQR();
        }
      }, 5000 * this.isDevSpeed);
    },
    loadingScreenBusiness() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.genereateVCard();
      }, 5000 * this.isDevSpeed);
    },
    generateQR() {
      if (this.url != null) {
        this.storedData = this.url;
        const qrCode = this.generateQatarQR1(this.storedData);

        this.generated = true;
        this.refreshGeneratedQR(qrCode);
      }
    },
    changeThemeQ1() {
      const qrCode = this.generateQatarQR1(this.storedData);
      this.refreshGeneratedQR(qrCode);
    },
    changeThemeQ2() {
      const qrCode = this.generateQatarQR2(this.storedData);
      this.refreshGeneratedQR(qrCode);
    },
    changeThemeQ3() {
      const qrCode = this.generateQatarQR3(this.storedData);
      this.refreshGeneratedQR(qrCode);
    },
    changeThemeQ4() {
      const qrCode = this.generateQatarQR4(this.storedData);
      this.refreshGeneratedQR(qrCode);
    },
    changeThemeQ5() {
      const qrCode = this.generateQatarQR5(this.storedData);
      this.refreshGeneratedQR(qrCode);
    },
    changeThemeG() {
      const qrCode = this.generateGreenQR(this.storedData);
      this.refreshGeneratedQR(qrCode);
    },
    changeThemeBW() {
      const qrCode = this.generateBlackAndWhiteQR(this.storedData);
      this.refreshGeneratedQR(qrCode);
    },
    generateQatarQR1(data) {
      var qrCode = new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        type: "canvas",
        data: data,
        image: qatar1,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevel: "H",
        },
        imageOptions: {
          imageSize: qrCodeImageSize
        },
        dotsOptions: {
          color: "#630e2b",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
      });
      return qrCode;
    },
    generateQatarQR2(data) {
      var qrCode = new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        type: "canvas",
        data: data,
        image: qatar2,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevel: "H",
        },
        imageOptions: {
          imageSize: qrCodeImageSize
        },
        dotsOptions: {
          color: "#aa243c",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
      });
      return qrCode;
    },
    generateQatarQR3(data) {
      var qrCode = new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        type: "canvas",
        data: data,
        image: qatar3,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevel: "H",
        },
        imageOptions: {
          imageSize: qrCodeImageSize
        },
        dotsOptions: {
          color: "#8e132f",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
      });
      return qrCode;
    },
    generateQatarQR4(data) {
      var qrCode = new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        type: "canvas",
        data: data,
        image: qatar4,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevel: "H",
        },
        imageOptions: {
          imageSize: qrCodeImageSize
        },
        dotsOptions: {
          color: "#70184a",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
      });
      return qrCode;
    },
    generateQatarQR5(data) {
      var qrCode = new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        type: "canvas",
        data: data,
        image: qatar5,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevel: "H",
        },
        imageOptions: {
          imageSize: qrCodeImageSize
        },
        dotsOptions: {
          color: "#56183c",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
      });
      return qrCode;
    },
    generateGreenQR(data) {
      var qrCode = new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        type: "canvas",
        data: data,
        image: greenLogo,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevel: "H",
        },
        imageOptions: {
          imageSize: qrCodeImageSize
        },
        dotsOptions: {
          color: "#128c78",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
      });
      return qrCode;
    },
    generateBlackAndWhiteQR(data) {
      var qrCode = new QRCodeStyling({
        width: qrCodeSize,
        height: qrCodeSize,
        type: "canvas",
        data: data,
        image: this.picture,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevel: "H",
        },
        dotsOptions: {
          color: "#000000",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
      });
      return qrCode;
    },
    refreshGeneratedQR(qrCode) {
      this.$nextTick(() => {
        const childs = document.getElementById("canvas").childNodes;
        childs.forEach((child) => {
          child.parentNode.removeChild(child);
        });
        qrCode.append(document.getElementById("canvas"));
        this.qr = qrCode;
        this.$forceUpdate();
      });
    },
    downloadPNG() {
      this.qr.download({ name: this.name, extension: "png" });
    },
    downloadJPEG() {
      this.qr.download({ name: this.name, extension: "jpeg" });
    },
    downloadSVG() {
      this.qr.download({ name: this.name, extension: "svg" });
    },
    resetInfo() {
      this.loadingScreen();
      this.name = null;
      this.picture = null;
      this.qr = null;
      this.url = null;
      this.expand = false;
      this.generated = false;
    },
    genereateVCard() {
      const newLine = "\n";
      let vCard = "BEGIN:VCARD";
      vCard += newLine + "VERSION:3.0";
      vCard += newLine + `N:${this.lastname};${this.firstname}`;
      vCard += newLine + `FN:${this.firstname} ${this.lastname}`;

      if (this.organization) {
        vCard += newLine + `ORG:${this.organization}`;
      }

      if (this.title) {
        vCard += newLine + `TITLE:${this.title}`;
      }

      if (this.telephone) {
        vCard += newLine + `TEL;WORK;VOICE:${this.telephone}`;
      }

      if (this.phone) {
        vCard += newLine + `TEL;CELL:${this.phone}`;
      }

      if (this.email) {
        vCard += newLine + `EMAIL;WORK;INTERNET:${this.email}`;
      }

      if (this.website) {
        vCard += newLine + `URL:${this.website}`;
      }

      vCard += newLine + "END:VCARD";

      this.storedData = vCard;
      const qrCode = this.generateGreenQR(this.storedData);

      this.generated = true;
      this.refreshGeneratedQR(qrCode);
    },
    async recordVisit() {
      const ID = "BitCode-QR";
      const URL =
        "https://us-central1-counters-b.cloudfunctions.net/counter?id=" + ID;
      const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
      if (!LOCAL_DOMAINS.includes(window.location.hostname)) {
        const response = await fetch(URL);
        if (response.ok) {
          const json = await response.json();
          // eslint-disable-next-line no-console
          console.log(json);
        } else {
          // eslint-disable-next-line no-console
          console.log("HTTP-Error: " + response.status);
        }
      }
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap');

*,
#app.v-application .text-h1,
#app.v-application .text-h2,
#app.v-application .text-h3,
#app.v-application .text-h4,
#app.v-application .text-h5,
#app.v-application .text-h6,
#app.v-application .text-body-1,
#app.v-application .text-body-2,
#app.v-application .text-subtitle-1,
#app.v-application .text-subtitle-2,
#app.v-application .text-caption,
#app.v-application .text-overline {
  font-family: 'Cairo', sans-serif !important;
}

.v-application .v-input .v-label {
  line-height: 16px;
}

canvas {
  max-height: 300px;
  max-width: 300px;
}
</style> 
