<template>
  <div>
    <Adsense
      :data-ad-client="googleAdId"
      :data-ad-slot="id"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense>
  </div>
</template>

<script>
export default {
  name: "GoogleAd",
  props: {
    id: String,
  },
  data() {
    return {
      googleAdId: "ca-pub-5360833601337219",
    };
  },
};
</script>
  