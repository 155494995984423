<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>

    <v-footer app height="75">
      <v-row justify="center">
        <v-col cols="auto">
          <a href="https://bitcode.sa/" target="_blank" style="text-decoration: none; color: #00a3e4">
            حقوق النشر والتوزيع — BitCode — {{ new Date().getFullYear() }} ©
          </a>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  mounted() {
    this.recordVisit()
  },

  methods: {
    recordVisit() {
      const data = new FormData()
      data.append('label', 'qr')
      data.append('tag', 'bitcode')

      const URL = 'https://visits.bitcode.sa/api/new'
      const LOCAL_DOMAINS = ['localhost', '127.0.0.1']
      if (!LOCAL_DOMAINS.includes(window.location.hostname)) {
        fetch(URL, { method: 'POST', body: data })
      }
    },
  },
};
</script>
