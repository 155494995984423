<script>
export default {
	data() {
		return {
			rules: {
				required: value => {
					if (value == undefined || value == null || value == "") {
						return "مطلوب";
					}

					return true;
				},
				password: value => {
					if (value == undefined || value == null || value == "") {
						return false;
					}

					if (value.length < 8) {
						return "كلمة السر يجب ان تكون من 8 خانات على الاقل";
					}

					return true;
				},
				optionalPassword: value => {
					if (value == undefined || value == null || value == "") {
						return true;
					}

					if (value.length < 8) {
						return "كلمة السر يجب ان تكون من 8 خانات على الاقل";
					}

					return true;
				},
				optionalPosNumber: value => {
					if (value === undefined || value === null || value === "") {
						return true;
					}

					if (Number(value) != value) {
						return "يجب ادخال رقم صحيح";
					}
					
					if (value <= 0) {
						return "يجب ادخال رقم موجب";
					}

					return true;
				},
				emailOrUsername: value => {
					if (value == null) {
						return false;
					}

					if (value.indexOf("@") != -1) {
						const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						if (pattern.test(value) == false) {
							return "بريد الكتروني غير صحيح";
						}
					} else {
						const pattern = /^[a-zA-Z0-9\u0600-\u06FF\s]{3,}$/;
						const pattern2 = /\s\s/;
						const pattern3 = /^\s.*$|^.*\s$/;

						if (pattern.test(value) == false) {
							return "تاكد من اسم المستخدم";
						}

						if (pattern2.test(value) == true) {
							return "لا يمكن استخدام اكثر من مسافة متتالية في اسم المستخدم";
						}

						if (pattern3.test(value) == true) {
							return "لا يمكن استخدام مسافة في بداية أو نهاية اسم المستخدم";
						}
					}
					return true;
				},
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (pattern.test(value) == false) {
						return "بريد الكتروني غير صحيح";
					}

					return true;
				},
				mobile: value => {
					const pattern = /^\+[0-9]{12,12}$/;
					if (pattern.test(value) == false) {
						return "رقم هاتف غير صحيح";
					}

					return true;
				},
				max2mb: value => {
					return (
						!value ||
						value.size < 2000000 ||
						"الصورة يجب ان تكون اقل من 2 MB!"
					);
				},
				number: value => {
					if (Number(value) == value && value == Math.round(value)) {
						return true;
					}
					return "يجب ادخال رقم صحيح";
				},
				posNumber: value => {
					if (value > 0) {
						return true;
					}
					return "يجب ادخال رقم موجب";
				},
				maxLength75: value => {
					if (value == null || value.length <= 75) {
						return true;
					}
					return "يجب ان لا يزيد عن 75 رمز";
				},
				maxLength150: value => {
					if (value == null || value.length <= 150) {
						return true;
					}
					return "يجب ان لا يزيد عن 150 رمز";
				},
				maxLength250: value => {
					if (value == null || value.length <= 250) {
						return true;
					}
					return "يجب ان لا يزيد عن 250 رمز";
				},
				maxLength255: value => {
					if (value == null || value.length <= 255) {
						return true;
					}
					return "يجب ان لا يزيد عن 255 رمز";
				},
				maxLength500: value => {
					if (value == null || value.length <= 500) {
						return true;
					}
					return "يجب ان لا يزيد عن 500 رمز";
				},
				limit3: value => {
					if (value == null || value.length <= 3) {
						return true;
					}
					return "يجب ان لا تزيد عن 3";
				},
				from1to6: value => {
					if (value >= 1 && value <= 6) {
						return true;
					}
					return "القيمة يجب ان تكون بين 1 و 6";
				},
				link: value => {
					if (value === undefined || value === null || value === "") {
						return false;
					}

					var lower = value.toLowerCase();
					if (
						lower.indexOf("https://") == 0 ||
						lower.indexOf("http://") == 0
					) {
						return true;
					}
					return "الرابط يجب ان يبدأ بقيمة //:https";
				},
				optionalLink: value => {
					if (value == undefined || value == null || value == "") {
						return true;
					}
					
					var lower = value.toLowerCase();
					if (
						lower.indexOf("https://") == 0 ||
						lower.indexOf("http://") == 0
					) {
						return true;
					}
					return "الرابط يجب ان يبدأ بقيمة //:https";
				},
				optionalImage: value => {
					if (value == undefined || value == null || value == "") {
						return true;
					}

					const extensions = ['jpeg', 'jpg', 'png']

					if (value.size && value.type.indexOf('image/') != -1) {
						var type = value.type.replace('image/', '');
						if (extensions.includes(type)) {
							return true;
						} else {
							return "الامتدادات المسموحة للصور هي " + extensions.join() + " فقط";
						}
					} else {
						return "يجب ان يكون نوع المرفق صورة من امتداد " + extensions.join();
					}
				},
				id: value => {
					if (value == undefined || value == null || value == "") {
						return false;
					}
					
					if (value.includes(".")) {
						return "يحب على المعرف ان لا يحتوي على نقطة";
					}

					if (value.includes(" ")) {
						return "يحب على المعرف ان لا يحتوي على مسافة";
					}

					const pattern = /^[a-zA-Z0-9_]*$/

					if (pattern.test(value)) {
						return true
					} else {
						return "يجب ان يحتوي المعرف على حروف وأرقام انجليزية فقط"
					}
				},
				endDateAfterStartDate: (start, end) =>{
					if (!start || !end) {
						return true;
					} else if (start <= end) {
						return true;
					} else {
						return "تاريخ النهاية يجب ان لا يكون قبل تاريخ البداية"
					}
				},
				endTimeAfterStartTime: (start, end) =>{
					if (!start || !end) {
						return true;
					} else if (start <= end) {
						return true;
					} else {
						return "وقت النهاية يجب ان لا يكون قبل وقت البداية"
					}
				}
			}
		};
	}
};
</script>
